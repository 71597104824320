/**
 * DateUtils
 */
const DateFormat = {
  JP_FULL: "YYYY年MM月DD日 HH時mm分",
}

import moment from "moment";

export default {
  data() {
    return {
      DateFormat: DateFormat,
    }
  },
  methods: {
    formatDate: function (date, format = DateFormat.JP_FULL) {
      return moment(date).format(format);
    },
  }
}
